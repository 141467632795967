@import '../index.scss';


footer {
    margin-top: 50px;
    background-color: darken($color: $color-secondary, $amount: 5);
    height: fit-content;
    min-height: 200px;
    font-size: smaller;
    color: lighten($color: $color-primary, $amount: 20);
  
  
    h4 {
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: small;
    }
  
    a {
      text-decoration: none;
      color: lighten($color: $color-primary, $amount: 20);
      margin: 5px;
    }
  
    .footer-nav {
      @include center;
      flex-direction: column;
      text-align: center;
  
      .certification img {
        height: 200px;
      }
  
      @include md {
        flex-direction: row;
        justify-content: space-around;
        align-items: baseline;
      }
  
      @include lg {
        flex-direction: row;
        justify-content: space-around;
        align-items: baseline;
  
        img {
          display: block;
        }
  
      }
  
      .mobile {
        @include center;
        text-align: center;
  
        .fa-mobile-screen-button {
          display: block;
          margin-right: 10px;
        }
      }
    }
  
    .legal-mentions p {
      text-align: right;
      font-size: x-small;
      margin-right: 10px;
      padding-bottom: 5px;
    }
  
    .location {
      margin-top: 0px;
    }
  }