@import '../index.scss';

.details-component {

    @include lg {
        display: flex;
        justify-content: space-around;
    }
    
}

.details {



    .details-title {
        padding: 30px;
        width: 90%;
        font-size:xx-large;
        border: 2px solid $color-secondary;
        border-radius: 25px;
        background-color: $color-primary;
    }


    @include lg {
      margin-left: 30px;
    }


}

.carousel-component {
    margin-top: 50px;
    width: 100%;

    @include lg {
        margin-left: 30px;
        width: 50%;
    }
}