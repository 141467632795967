@import '../index.scss';

.contact-title {
    margin-top : 60px;
    margin-bottom: 50px;
    font-size: xx-large;
    color: $color-secondary;
    border:2px solid $color-secondary;
    border-radius: 15px;
    background-color: $color-primary;
}

.contact-form-container {

    @include center;
    color: darken($color: $color-secondary, $amount: 10);
    margin-top: 30px;

    form {
        width: 90%;

        .contact-form-question {
            margin-bottom: 10px;
            width: 100%;
            @include center;

            label {
                display: none;
            }

            input {
                width: 100%;
                max-width: 300px;
                height: 25px;
                font-size: 18px;
                color: darken($color: $color-secondary, $amount: 10);
            }
        }

        textarea {
            min-height: 300px;
            width: 100%;
            max-width: 400px;
            line-height: 1.5;
            font-size: 14px;
            color: darken($color: $color-secondary, $amount: 10);
            font-family: 'Sora', sans-serif;
        }
        

        .contact-form-submit input {
            background-color: darken($color: $color-secondary, $amount: 20);
            color: lighten($color: $color-primary, $amount: 20);
            height: 40px;
            width: 100px;
            border-radius: 30px;
            border: none;
            font-family: 'Sora', sans-serif;

            &:hover {

                transform: scale(1.01);
                box-shadow: 0 3px 5px 0 $color-secondary;
                cursor: pointer;

            }
        }

        @include md {
            display: flex;
            justify-content: space-around;

            .contact-form-question input {
                min-width: 160px;
            }            

            #text {
                width: 300px;
                margin-left: 20px;
                margin-right: 20px;
            }

            .contact-form-submit {
                @include center;
            }
        }

        @include lg {
            display: flex;
            justify-content: space-around;

            .contact-form-question input {
                min-width: 250px;
            } 

            #text {
                width: 400px;
            }

            .contact-form-submit {
                @include center;
            }
        }
    }
}

.required-field {
    margin-right: 30px;
    text-align: right;
}

.call-us {
    text-align: center;
    margin-top: 30px;
}
