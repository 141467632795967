@import '../index.scss';

header {


    @include center;
    justify-content: space-around;
    flex-wrap: wrap;
    height: fit-content;
    min-height: 200px;
    background-color: $color-secondary;
    color: lighten($color: $color-primary, $amount: 10);
    width: 100%;


  

  
    img {
      height: 100%;
      max-height: 150px;
      margin: 20px;
    }
  
    p {
      font-size: 48px;
      text-align: center;
      font-family: 'Audiowide', sans-serif;
      line-height: 52px;
      padding-left: 20px;
      padding-right: 20px;
  
    }
  
}
  
  