$color-primary: #FFCE00;
$color-secondary: #007cb0;
$color-tertiary: #4E5166;


@mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

@mixin sm {
    @media screen and (max-width:679px) {
        @content;
    }
}

@mixin md {
    @media screen and (min-width:680px) and (max-width:991px) {
        @content;
    }
}

@mixin lg {
    @media screen and (min-width:992px) {
        @content;
    }
}
