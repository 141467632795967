@import '../index.scss';

.prez {
    // display: flex;
    width: 100%;


    .prez-text {
        width: 90%;
        margin: 30px;

        .prez-title {
            width: 90%;
            margin-right: 30px;
        }

        .prez-paragraph {
            width: 100%;
            margin-right: 30px;

            p {
                width: 100%;
            }

        }
    }

    img {
        width: 90%;
        margin: 30px;

        @include md {
            width: 40rem;
        }
        @include lg {
            width: 40rem;
        }
    }

   

    @include lg {
        margin-top: 50px;
        display: flex;
    }
}